import React, {useCallback, useEffect, useState} from 'react';
import {NebiusThunkDispatch, useNebiusDispatch} from 'store/nebius-dispatch';
import {
    executeFocusedCell,
    executeNotebookWithRequiredJupyt,
} from 'features/Jupyter/store/actions/execute';
import {CommandPalette} from 'features/Jupyter/components/CommandPalette/CommandPalette';
import {KeyEnum} from 'features/Jupyter/types';
import {NebiusRootState} from 'store/reducers';
import {
    selectFocusedCellId,
    selectFocusedCellIndex,
} from 'features/Jupyter/store/selectors/notebook';
import {notebookSlice} from 'features/Jupyter/store/slices/notebook';

enum CommandPaletteAction {
    RunAllCells = 'Run all cells',
    RunFocusedCell = 'Run focused cell',

    AddCodeCellAbove = 'Add code cell above',
    AddCodeCellBelow = 'Add code cell below',

    AddMarkdownCellAbove = 'Add markdown cell above',
    AddMarkdownCellBelow = 'Add markdown cell below',

    DeleteCell = 'Delete focused cell',

    ClearFocusedCellOuput = 'Clear focused cell output',
    ClearOuputOfAllCells = 'Clear all cells outputs',
}

const items = Object.values(CommandPaletteAction).map((value) => ({
    value,
}));

const processCommand = (command: CommandPaletteAction) => {
    return (dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        switch (command) {
            case CommandPaletteAction.RunAllCells: {
                dispatch(executeNotebookWithRequiredJupyt());
                break;
            }
            case CommandPaletteAction.RunFocusedCell: {
                dispatch(executeFocusedCell());
                break;
            }
            case CommandPaletteAction.AddCodeCellAbove: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    notebookSlice.actions.addCellAfter({
                        currentIndex: focusedCellIndex - 1,
                        type: 'code',
                    }),
                );
                break;
            }
            case CommandPaletteAction.AddCodeCellBelow: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    notebookSlice.actions.addCellAfter({
                        currentIndex: focusedCellIndex,
                        type: 'code',
                    }),
                );
                break;
            }
            case CommandPaletteAction.AddMarkdownCellAbove: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    notebookSlice.actions.addCellAfter({
                        currentIndex: focusedCellIndex - 1,
                        type: 'markdown',
                    }),
                );
                break;
            }
            case CommandPaletteAction.AddMarkdownCellBelow: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(
                    notebookSlice.actions.addCellAfter({
                        currentIndex: focusedCellIndex,
                        type: 'markdown',
                    }),
                );
                break;
            }
            case CommandPaletteAction.DeleteCell: {
                const focusedCellIndex = selectFocusedCellIndex(getState());
                dispatch(notebookSlice.actions.deleteCell({currentIndex: focusedCellIndex}));
                dispatch(notebookSlice.actions.setFocusedCellByIndex({index: focusedCellIndex}));
                break;
            }
            case CommandPaletteAction.ClearOuputOfAllCells: {
                dispatch(notebookSlice.actions.clearCellsOutputs());
                break;
            }
            case CommandPaletteAction.ClearFocusedCellOuput: {
                const focusedCellId = selectFocusedCellId(getState());
                dispatch(
                    notebookSlice.actions.setCellOutputs({
                        cellId: focusedCellId,
                        outputs: [],
                    }),
                );
                break;
            }
        }
    };
};

export const CommandPaletteContainer = () => {
    const [open, setOpen] = useState(false);
    const dispatch = useNebiusDispatch();

    const onUpdate = useCallback(([value]: string[]) => {
        setOpen(false);
        dispatch(processCommand(value as CommandPaletteAction));
    }, []);

    const onOpenChange = useCallback((open: boolean) => {
        setOpen(open);
    }, []);

    useEffect(() => {
        let lastShiftPress = 0;

        function onKeyDown(event: KeyboardEvent) {
            const isCommandP = event.metaKey && event.key.toLowerCase() === KeyEnum.P;
            const isCtrlP = event.ctrlKey && event.key.toLowerCase() === KeyEnum.P;
            const isShift = event.key === KeyEnum.SHIFT;
            const isEscape = event.key === KeyEnum.ESC;

            if (!isShift) {
                lastShiftPress = 0;
            }

            switch (true) {
                case isCommandP:
                case isCtrlP: {
                    event.preventDefault();
                    setOpen(true);
                    break;
                }
                case isShift: {
                    const now = Date.now();

                    if (now - lastShiftPress <= 1000) {
                        setOpen(true);
                        lastShiftPress = 0;
                    } else {
                        lastShiftPress = now;
                    }

                    break;
                }
                case isEscape: {
                    setOpen(false);
                    break;
                }
            }
        }

        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, []);

    return (
        <CommandPalette items={items} onUpdate={onUpdate} onOpenChange={onOpenChange} open={open} />
    );
};
