import React, {useCallback, useMemo} from 'react';

import {useSelector} from 'react-redux';
import {selectModalCreateNotebookState} from '../../../store/selectors/modals';
import {YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import {createNotebook} from '../../../store/actions/notebook';
import {modalCreateNotebookSlice} from '../../../store/slices/modals/modal-create-notebook';
import {useNebiusDispatch} from '../../../../../store/nebius-dispatch';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {updateView} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation';
import {useHistory} from 'react-router';
import {getCluster, getCurrentUserName} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import nebiusLocalStorage from 'utils/nebius-local-storage';
import {getOperationAlias} from 'features/Jupyter/store/selectors/jupytOperation';

type FormValues = {notebookPath: string};

type NotebookSaveDialogProps = {
    path: string;
    onAdd: (payload: {path: string}) => void;
};

const NotebookSaveDialog = (props: NotebookSaveDialogProps) => {
    const dispatch = useNebiusDispatch();
    const state = useSelector(selectModalCreateNotebookState);

    const handleIsApplyDisabled = useCallback(() => {
        return false;
    }, []);

    return (
        <YTDFDialog<FormValues>
            visible={state.visible}
            headerProps={{title: 'Create notebook'}}
            onAdd={(args) => {
                const path = args.getState().values.notebookPath;
                return dispatch(createNotebook(path))
                    .then(({payload}) =>
                        payload
                            ? dispatch(modalCreateNotebookSlice.actions.close())
                            : Promise.reject(),
                    )
                    .then(() => props.onAdd({path}));
            }}
            onClose={() => {
                dispatch(modalCreateNotebookSlice.actions.close());
            }}
            fields={[
                {
                    name: 'notebookPath',
                    type: 'text',
                    caption: 'Notebook path',
                    tooltip: 'Path to save notebook',
                    initialValue: `${props.path}`,
                },
            ]}
            isApplyDisabled={handleIsApplyDisabled}
        />
    );
};

export const NavigationNotebookSaveDialog = () => {
    const dispatch = useNebiusDispatch();
    const onAdd = useCallback(() => {
        dispatch(updateView());
    }, []);
    const path = `${useSelector(getPath)}/New notebook.ipynb`;
    return <NotebookSaveDialog onAdd={onAdd} path={path} />;
};

export const KernelNotebookSaveDialog = () => {
    const history = useHistory();
    const cluster = useSelector(getCluster);
    const user = useSelector(getCurrentUserName);
    const alias = useSelector(getOperationAlias);
    const onAdd = useCallback(
        ({path}: {path: string}) => {
            if (alias) {
                nebiusLocalStorage.storeNotebook({
                    id: path,
                    defaultAlias: alias,
                });
            }
            history.push(`/${cluster}/navigation?path=${path}`);
        },
        [history, alias],
    );
    const path = useMemo(() => {
        return `//tmp/temporary_notebooks/${user}-notebook-${crypto.randomUUID().slice(0, 8)}.ipynb`;
    }, []);
    return <NotebookSaveDialog onAdd={onAdd} path={path} />;
};
