import type nbformat from '@jupyterlab/nbformat';

type LocalStorageNotebook = {
    id: string;
    content?: nbformat.INotebookContent;
    defaultAlias?: string;
};

export interface YTNebiusStorageData {
    notebooks: LocalStorageNotebook[];
}

export function storageKey(key: keyof YTNebiusStorageData) {
    return `yt_nebius_${key}`;
}

const MAX_NOTEBOOKS_TO_STORE = 40;

class NebiusLocalStorage {
    getNotebook(id: string): undefined | LocalStorageNotebook {
        const notebooks = this.get('notebooks');

        if (!Array.isArray(notebooks)) {
            return;
        }

        return notebooks.find((item) => item?.id === id);
    }

    storeNotebook(value: LocalStorageNotebook) {
        let notebooks = this.get('notebooks');

        if (!Array.isArray(notebooks)) {
            notebooks = [value];
        } else {
            const existingValue = notebooks.find((item) => item.id === value.id);
            if (existingValue) {
                Object.assign(existingValue, value);
            } else {
                notebooks.unshift(value);
            }
        }

        notebooks = notebooks.slice(0, MAX_NOTEBOOKS_TO_STORE);

        this.set('notebooks', notebooks);
    }

    private get<K extends keyof YTNebiusStorageData>(k: K): undefined | YTNebiusStorageData[K] {
        try {
            const rawValue = localStorage.getItem(storageKey(k));
            return rawValue !== null && rawValue !== undefined ? JSON.parse(rawValue) : [];
        } catch {
            return undefined;
        }
    }

    private set<K extends keyof YTNebiusStorageData, V extends YTNebiusStorageData[K]>(k: K, v: V) {
        localStorage.setItem(storageKey(k), JSON.stringify(v));
    }
}

export default new NebiusLocalStorage();
