import {USE_SUPRESS_SYNC} from '@ytsaurus-ui-platform/src/shared/constants';
import {ytApiV3} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import _chunk from 'lodash/chunk';
import {
    UniverseDiskResourceType,
    UniverseDiskUsageAndLimits,
    UniverseResourceType,
    UniverseResourceUsageAndLimits,
    UniverseUIConfigType,
} from '../types/resources';
import {NebiusLogger} from 'utils/logging';

type PoolTreesListType = string[];

export const fetchPoolTrees = (): Promise<PoolTreesListType> => {
    const requests = [
        {
            command: 'list' as const,
            parameters: {
                path: '//sys/pool_trees',
                ...USE_SUPRESS_SYNC,
            },
        },
    ];

    return ytApiV3.executeBatch<PoolTreesListType>({requests}).then((response) => {
        NebiusLogger.log('fetchPoolTrees raw response: ', response);

        const [result] = response;

        if (result.error) {
            console.error(result.error);
            return [];
        }

        return result.output || [];
    });
};

export type FetchResourcesUsageAndLimitsResponse = {
    universeDiskResource: UniverseDiskUsageAndLimits;
    universeResourceByPoolTree: Record<string, UniverseResourceUsageAndLimits>;
};

export const fetchResourcesUsageAndLimits = (
    poolTreesList: PoolTreesListType,
): Promise<FetchResourcesUsageAndLimitsResponse> => {
    const requests = [
        {
            command: 'get' as const,
            parameters: {
                path: `//sys/accounts/universe/@resource_usage`,
                ...USE_SUPRESS_SYNC,
            },
        },
        {
            command: 'get' as const,
            parameters: {
                path: `//sys/accounts/universe/@resource_limits`,
                ...USE_SUPRESS_SYNC,
            },
        },
    ];

    poolTreesList.forEach((poolTree) => {
        requests.push(
            {
                command: 'get' as const,
                parameters: {
                    path: `//sys/pool_trees/${poolTree}/@ui_config`,
                    ...USE_SUPRESS_SYNC,
                },
            },
            {
                command: 'get' as const,
                parameters: {
                    path: `//sys/scheduler/orchid/scheduler/pool_trees/${poolTree}/pools/universe/resource_usage`,
                    ...USE_SUPRESS_SYNC,
                },
            },
            {
                command: 'get' as const,
                parameters: {
                    path: `//sys/scheduler/orchid/scheduler/pool_trees/${poolTree}/pools/universe/resource_limits`,
                    ...USE_SUPRESS_SYNC,
                },
            },
        );
    });

    return ytApiV3
        .executeBatch<
            UniverseResourceType | UniverseDiskResourceType | UniverseUIConfigType
        >({requests})
        .then((response) => {
            NebiusLogger.log('fetchResourcesUsageAndLimits raw response: ', response);

            const [diskUsage, diskLimits, ...restResponse] = response;

            const universeResourceByPoolTree: Record<string, UniverseResourceUsageAndLimits> = {};

            const universeDiskResource: UniverseDiskUsageAndLimits = {
                resource_usage: diskUsage.output as UniverseDiskResourceType,
                resource_limits: diskLimits.output as UniverseDiskResourceType,
            };

            const poolTreesResult = _chunk(restResponse, 3);

            poolTreesList.forEach((poolTree, index) => {
                const [uiConfig, resourceUsage, resourceLimits] = poolTreesResult[index];
                universeResourceByPoolTree[poolTree] = {
                    ui_config: (uiConfig.output || {}) as UniverseUIConfigType,
                    resource_usage: resourceUsage.output as UniverseResourceType,
                    resource_limits: resourceLimits.output as UniverseResourceType,
                };
            });

            return {
                universeDiskResource,
                universeResourceByPoolTree,
            };
        });
};
