import React, {useCallback} from 'react';
import RadioButton from '@ytsaurus-ui-platform/src/ui/components/RadioButton/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import {
    selectIsUniversePoolExist,
    selectVisibleResourceType,
} from 'features/UniverseResources/store/selectors/resources';
import {ResourceType, resourcesSlice} from 'features/UniverseResources/store/slices/resources';
import block from 'bem-cn-lite';

import './ResourcesTypeSwitch.scss';

const b = block('resources-type-switch');

const resourcesRadioButtonItems = [
    {value: ResourceType.universe, text: 'Universe'},
    {value: ResourceType.global, text: 'Global'},
];

export const ResourcesTypeSwitch = () => {
    const dispatch = useDispatch();
    const value = useSelector(selectVisibleResourceType);
    const isUniversePoolExist = useSelector(selectIsUniversePoolExist);
    const onUpdate = useCallback((value: string) => {
        dispatch(resourcesSlice.actions.setVisibleResourceType({value: value as ResourceType}));
    }, []);

    if (!isUniversePoolExist) {
        return null;
    }

    return (
        <div className={b()}>
            Resources type
            <RadioButton
                className={b('radio-button')}
                value={value}
                items={resourcesRadioButtonItems}
                onUpdate={onUpdate}
            />
        </div>
    );
};
