import React from 'react';
import {Progress} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';
import {ResourceEntry} from 'features/UniverseResources/types/resources';
import {ResourcesComponentTooltip} from '../ResourcesComponentTooltip/ResourcesComponentTooltip';
import './ResourcesComponent.scss';

const b = block('system');

type ResourcesComponentProps = {
    title: string;
    hint: React.ReactNode;
    entries: ResourceEntry[];
};

export const ResourcesComponent: React.FC<ResourcesComponentProps> = ({title, hint, entries}) => {
    return (
        <>
            <div
                key="resources"
                className={b('resources-heading', null, b('tracto-resources-heading'))}
            >
                {title}
                <ResourcesComponentTooltip content={hint} />
            </div>
            <ResourcesList key="resources-list" entries={entries} />
        </>
    );
};

type ResourcesListProps = {
    entries: ResourceEntry[];
};

const ResourcesList: React.FC<ResourcesListProps> = ({entries}) => {
    return entries.map(({caption, usage, hidden, tooltipContent}) => {
        const tooltip = tooltipContent ? (
            <ResourcesComponentTooltip content={tooltipContent} />
        ) : null;
        return (
            <div
                key={caption}
                className={b('resource')}
                style={{visibility: hidden ? 'hidden' : undefined}}
            >
                <div className={b('resources-caption', null, b('tracto-resources-caption'))}>
                    {caption} {tooltip}
                </div>
                <div className={b('resources-progress')}>
                    <Progress theme={'success'} text={usage.text} value={usage.progress} />
                </div>
            </div>
        );
    });
};
