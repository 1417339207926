import React from 'react';
import hammer from '@ytsaurus-ui-platform/src/ui/common/hammer';
import {ResourceEntry, UniverseResourceType, UniverseUIConfigType} from '../types/resources';

const formatNumber = hammer.format.Number;
const formatBytes = hammer.format.Bytes;

export const mapResourcesToResourceEntry = (payload: {
    poolTree: string;
    resources: {
        resource_usage: UniverseResourceType;
        resource_limits: UniverseResourceType;
    };
}): ResourceEntry[] => {
    const {resource_usage: usage, resource_limits: limits} = payload.resources;

    return [
        {
            caption: 'CPU',
            usage: {
                text: formatNumber(usage?.cpu) + ' / ' + formatNumber(limits?.cpu),
                progress: limits?.cpu ? (usage.cpu / limits.cpu) * 100 : 0,
            },
        },
        {
            caption: 'Memory',
            usage: {
                text: formatBytes(usage?.user_memory) + ' / ' + formatBytes(limits?.user_memory),
                progress: limits?.user_memory ? (usage.user_memory / limits.user_memory) * 100 : 0,
            },
        },
        {
            caption: 'GPU',
            hidden: !payload.poolTree.includes('gpu'),
            usage: {
                text: formatNumber(usage?.gpu) + ' / ' + formatNumber(limits?.gpu),
                progress: limits?.gpu ? (usage.gpu / limits.gpu) * 100 : 0,
            },
        },
    ];
};

type PrepareDiskReousrcesPayload = {
    availableSpacePerMedium: Record<string, number>;
    usedSpacePerMedium: Record<string, number>;
    mediumList: string[];
};

export function prepareDiskResources({
    availableSpacePerMedium,
    usedSpacePerMedium,
    mediumList,
}: PrepareDiskReousrcesPayload) {
    const diskResourcesPerMedium: ResourceEntry[] = [];

    mediumList.forEach((medium) => {
        const available = availableSpacePerMedium[medium];
        const used = usedSpacePerMedium[medium];

        if (available > 0 || used > 0) {
            /* eslint-disable new-cap */
            const total = available + used;
            const isDefaultMedium = medium === 'default';
            const caption = isDefaultMedium
                ? hammer.format['ReadableField']('HDD')
                : hammer.format['ReadableField'](medium);
            const progressText =
                hammer.format['Bytes'](used) + ' / ' + hammer.format['Bytes'](total);
            const progressValue = (used / total) * 100;

            diskResourcesPerMedium.push({
                caption: caption,
                tooltipContent: isDefaultMedium
                    ? 'In YTsaurus terms, HDD storage corresponds to a "default" medium'
                    : undefined,
                usage: {
                    text: progressText,
                    progress: progressValue,
                },
            });
            /* eslint-enable new-cap */
        }
    });

    return diskResourcesPerMedium;
}

const titleFallbackMap: Record<string, string> = {
    default: 'Static CPU compute',
    elastic: 'Elastic CPU compute',
    default_gpu_l40s: 'Static GPU L40s compute',
    universe: 'Storage',
};

const hintFallbackMap: Record<string, React.ReactNode> = {
    default: (
        <>
            Static CPU compute resources included in your TractoAI tier package;
            <br />
            in YTsaurus terms, they correspond to the &quot;pool universe&quot; in the
            &quot;default&quot; scheduler pool tree
        </>
    ),
    elastic: (
        <>
            Elastic compute resources allocated on-demand with pay-as-you-go-pricing;
            <br />
            in YTsaurus terms, they correspond to the pool &quot;universe&quot; in the
            &quot;elastic&quot; scheduler pool tree
        </>
    ),
    default_gpu_l40s: (
        <>
            Static GPU L40s compute resources included in your TractoAI tier package; in YTsaurus
            terms, they correspond to the pool &quot;universe&quot; in the &quot;default_l40s&quot;
            scheduler pool tree
        </>
    ),
    universe: <>In YTsaurus terms, disk space corresponds to an account &quot;universe&quot;</>,
};

export const mapPoolTreeToTitle = (poolTree: string, uiConfig: UniverseUIConfigType) => {
    return uiConfig.title || titleFallbackMap[poolTree] || poolTree;
};

export const mapPoolTreeToHint = (poolTree: string, uiConfig: UniverseUIConfigType) => {
    return uiConfig.hint || hintFallbackMap[poolTree];
};
