import React from 'react';
import Select, {Item} from '@ytsaurus-ui-platform/src/ui/components/Select/Select';
import block from 'bem-cn-lite';
import './CommandPalette.scss';

const b = block('command-palette');

type CommandPaletteProps = {
    open: boolean;
    items: Item[];
    onOpenChange: (open: boolean) => void;
    onUpdate: (value: string[]) => void;
};

const filterOption = (item: Item, filter: string) => {
    const words = filter.toUpperCase().split(' ').filter(Boolean);

    if (!words.length) {
        return true;
    }

    const value = item.value.toUpperCase();

    return words.every((word: string) => value.includes(word));
};

export const CommandPalette = ({open, items, onOpenChange, onUpdate}: CommandPaletteProps) => {
    return (
        <div className={b()}>
            <Select
                items={items}
                onUpdate={onUpdate}
                filterable
                filterOption={filterOption}
                open={open}
                size="xl"
                popupWidth={400}
                onOpenChange={onOpenChange}
            />
        </div>
    );
};
