import {createAsyncThunk} from '@reduxjs/toolkit';
import {
    getCluster,
    getCurrentUserName,
    isDeveloper,
} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {RootState} from '@ytsaurus-ui-platform/src/ui/store/reducers';
import CancelHelper, {isCancelled} from '@ytsaurus-ui-platform/src/ui/utils/cancel-helper';
import {SettingsThunkAction, setSetting} from '@ytsaurus-ui-platform/src/ui/store/actions/settings';
import {
    type JupytApi,
    type JupytListAttributes,
    type JupytListResponseItem,
    jupytApiAction,
} from 'features/Jupyter/api/jupyt';
import {NebiusThunkDispatch} from 'store/nebius-dispatch';
import {NebiusRootState} from 'store/reducers';
import {JupytDefaultsType, jupytSlice} from '../slices/jupyt';
import {jupytOperationSlice} from '../slices/jupytOperation';
import {getNotebookCypressId, selectCurrentJupytAlias} from '../selectors/notebook';
import {JupyterApi} from '../../api/kernel';
import {selectJupytOperations} from '../selectors/jupyt';
import {SYSTEM_JUPYT_ALIAS} from '../../../../../shared/constants/jupyter/jupyter-api';
import {Kernel, ServerConnection} from '@jupyterlab/services';
import {delay} from '@ytsaurus-ui-platform/src/ui/packages/delays';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {getJupyterBaseUrl} from '../../../../../shared/utils/jupyter/jupyter-api';
import {ytApiV4} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import {JupytSecretsMapType} from '../../../../../shared/types/jupyt/secrets';
import nebiusLocalStorage from 'utils/nebius-local-storage';

const cancelHelper = new CancelHelper();

function _loadJupytList() {
    return async (_dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        const state = getState();
        const cluster = getCluster(state);
        const isAdmin = isDeveloper(state);

        const attributesSet = new Set([
            'yt_operation_id' as const,
            'creator' as const,
            'state' as const,
            'health' as const,
            'health_reason' as const,
            'cpu' as const,
            'memory' as const,
            'docker_image' as const,
            'creation_time' as const,
            'pool' as const,
            'speclet_modification_time' as const,
            'yt_operation_finish_time' as const,
            'yt_operation_start_time' as const,
            'strawberry_state_modification_time' as const,
        ]);

        const {result} = await jupytApiAction(
            'list',
            cluster,
            {attributes: [...attributesSet]},
            {isAdmin, cancelToken: cancelHelper.removeAllAndGenerateNextToken()},
        );
        const currentUserName = getCurrentUserName(getState());
        return result
            .filter((item) => item.$value !== SYSTEM_JUPYT_ALIAS)
            .sort((a) => (a.$attributes?.creator === currentUserName ? -1 : 1));
    };
}

const getJupytDefaults = () => {
    return ytApiV4.get<JupytDefaultsType>({
        path: `//sys/jupyt/defaults`,
    });
};

export function loadJupytDefaults() {
    return async (dispatch: NebiusThunkDispatch) => {
        const {value} = await getJupytDefaults();

        dispatch(jupytSlice.actions.setJupytDefaults({jupytDefaults: value}));
    };
}

export function loadNotebookJupytList() {
    return async (dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        try {
            const username = getCurrentUserName(getState());
            const result = await dispatch(_loadJupytList());

            const jupytList = result
                .filter((item) => item.$attributes?.creator === username)
                .sort((a, b) => a.$value.localeCompare(b.$value));

            dispatch(jupytSlice.actions.setJupytList({operations: jupytList}));
        } catch (error) {
            if (!isCancelled(error)) {
                console.log('error', error);
            }
        }
    };
}

export function setInitialJupytAlias() {
    return (dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        const path = getPath(getState());
        const jupytList = selectJupytOperations(getState());
        const defaultAlias = nebiusLocalStorage.getNotebook(path)?.defaultAlias;
        const alias = selectCurrentJupytAlias(getState());

        if (alias) {
            return;
        }

        if (!jupytList.length) {
            return;
        }

        let defaultJupytAlias = jupytList[0]?.$value || '';

        if (defaultAlias) {
            const jupytAliasFromNotebook = jupytList.find((op) => op.$value === defaultAlias);

            if (jupytAliasFromNotebook) {
                defaultJupytAlias = jupytAliasFromNotebook.$value;
            }
        }

        dispatch(setCurrentJupytAlias({alias: defaultJupytAlias}));
    };
}

export function loadJupytPageList() {
    return async (dispatch: NebiusThunkDispatch) => {
        try {
            const result = await dispatch(_loadJupytList());

            dispatch(jupytSlice.actions.setJupytList({operations: result}));
        } catch (error) {
            if (!isCancelled(error)) {
                console.log('error', error);
            }
        }
    };
}

type CreateJupytArgs = {
    alias: Extract<JupytApi, {action: 'create'}>['params']['alias'];
    speclet_options: Extract<JupytApi, {action: 'create'}>['params']['speclet_options'];
    secrets: Extract<JupytApi, {action: 'create'}>['params']['secrets'];
};

export function createJupyt(args: CreateJupytArgs) {
    return (_dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        const state = getState();
        const cluster = getCluster(state);
        const isAdmin = isDeveloper(state);

        return jupytApiAction(
            'create',
            cluster,
            {
                alias: args.alias,
                speclet_options: {
                    ...args.speclet_options,
                },
                secrets: {
                    ...args.secrets,
                },
            },
            {isAdmin, cancelToken: cancelHelper.removeAllAndGenerateNextToken()},
        ).catch((error) => {
            if (!isCancelled(error)) {
                console.log('error', error);
            }
        });
    };
}

type GetJupytSecretsArgs = {
    alias: Extract<JupytApi, {action: 'get_secrets'}>['params']['alias'];
};

export function getJupytSecrets(args: GetJupytSecretsArgs) {
    return async (
        _dispatch: NebiusThunkDispatch,
        getState: () => NebiusRootState,
    ): Promise<JupytSecretsMapType> => {
        const state = getState();
        const cluster = getCluster(state);
        const isAdmin = isDeveloper(state);

        const respose = await jupytApiAction(
            'get_secrets',
            cluster,
            {
                alias: args.alias,
            },
            {isAdmin, cancelToken: cancelHelper.removeAllAndGenerateNextToken()},
        );

        return respose.result || {};
    };
}

type SetJupytSecretsArgs = {
    alias: Extract<JupytApi, {action: 'set_secrets'}>['params']['alias'];
    secrets: Extract<JupytApi, {action: 'set_secrets'}>['params']['secrets'];
};

export function setJupytSecrets({alias, secrets}: SetJupytSecretsArgs) {
    return (_dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        const state = getState();
        const cluster = getCluster(state);
        const isAdmin = isDeveloper(state);

        return jupytApiAction(
            'set_secrets',
            cluster,
            {
                alias,
                secrets,
            },
            {isAdmin, cancelToken: cancelHelper.removeAllAndGenerateNextToken()},
        );
    };
}

export function removeJupyt(params: {alias: string}) {
    return (_dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        const state = getState();
        const cluster = getCluster(state);
        const isAdmin = isDeveloper(state);

        return jupytApiAction(
            'remove',
            cluster,
            {
                alias: params.alias,
            },
            {isAdmin, cancelToken: cancelHelper.removeAllAndGenerateNextToken()},
        ).catch((error) => {
            if (!isCancelled(error)) {
                console.log('error', error);
            }
        });
    };
}

export function removeCurrentJupyt() {
    return (dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        const state = getState();
        const alias = selectCurrentJupytAlias(state);

        return dispatch(removeJupyt({alias}));
    };
}

export function startJupyt(params: {alias: string}) {
    return (_dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        const state = getState();
        const cluster = getCluster(state);
        const isAdmin = isDeveloper(state);

        return jupytApiAction(
            'start',
            cluster,
            {
                alias: params.alias,
            },
            {isAdmin, cancelToken: cancelHelper.removeAllAndGenerateNextToken()},
        ).catch((error) => {
            if (!isCancelled(error)) {
                console.log('error', error);
            }
        });
    };
}

export function stopJupyt(params: {alias: string}) {
    return (_dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        const state = getState();
        const cluster = getCluster(state);
        const isAdmin = isDeveloper(state);

        return jupytApiAction(
            'stop',
            cluster,
            {
                alias: params.alias,
            },
            {isAdmin, cancelToken: cancelHelper.removeAllAndGenerateNextToken()},
        ).catch((error) => {
            if (!isCancelled(error)) {
                console.log('error', error);
            }
        });
    };
}

export function setCurrentJupytAlias({alias}: {alias: string}) {
    return (dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        const cluster = getCluster(getState());
        const path = getPath(getState());
        const notebookId = getNotebookCypressId(getState());

        if (!notebookId) {
            throw new Error('notebookId is required');
        }

        JupyterApi.disposeConnection();

        dispatch(jupytSlice.actions.setCurrentJupytAlias({alias}));
        nebiusLocalStorage.storeNotebook({id: path, defaultAlias: alias});

        const onKernelStatusChange = (_: Kernel.IKernelConnection, status: Kernel.Status) => {
            dispatch(jupytSlice.actions.setJupyterKernelStatus({status}));
        };

        JupyterApi.setupKernelConnection({alias, cluster, path, notebookId, onKernelStatusChange});
    };
}

export function jupytSetVisibleColumns(columns: Array<string>): SettingsThunkAction {
    return (dispatch) => {
        return dispatch(
            setSetting(
                'list_columns',
                {
                    name: '',
                    value: 'global::jupyt',
                },
                columns,
            ),
        );
    };
}

export const jupytOperationLoad = createAsyncThunk(
    'jupyter.jupytOperation/JupytLoad',
    async (alias: string, thunkAPI) => {
        const state = thunkAPI.getState();
        const cluster = getCluster(state as RootState);
        const isAdmin = isDeveloper(state);

        return jupytApiAction<'get_brief_info'>(
            'get_brief_info',
            cluster,
            {alias},
            {
                isAdmin,
                skipErrorToast: true,
            },
        ).catch((error) => {
            if (!isCancelled(error)) {
                console.log('error', error);
                throw error;
            }
        });
    },
);

export const jupytSpecletLoad = createAsyncThunk(
    'jupyter.jupytOperation/SpecletLoad',
    async (alias: string, thunkAPI) => {
        const state = thunkAPI.getState();
        const cluster = getCluster(state as RootState);
        const isAdmin = isDeveloper(state);

        return jupytApiAction(
            'get_speclet',
            cluster,
            {alias},
            {
                isAdmin,
                cancelToken: cancelHelper.removeAllAndGenerateNextToken(),
                skipErrorToast: true,
            },
        ).catch((error) => {
            if (!isCancelled(error)) {
                console.log('error', error);
                throw error;
            }
        });
    },
);

export const jupytOptionsLoad = createAsyncThunk(
    'jupyter.jupytOperation/OptionsLoad',
    async (alias: string, thunkAPI) => {
        const state = thunkAPI.getState();
        const cluster = getCluster(state as RootState);
        const isAdmin = isDeveloper(state);

        return jupytApiAction(
            'describe_options',
            cluster,
            {alias},
            {
                isAdmin,
                cancelToken: cancelHelper.removeAllAndGenerateNextToken(),
                skipErrorToast: true,
            },
        ).catch((error) => {
            if (!isCancelled(error)) {
                console.log('error', error);

                throw error;
            }
        });
    },
);

export const jupytOptionsEdit = createAsyncThunk<
    unknown,
    {
        alias: string;
        options: Required<JupytListResponseItem>['$attributes'];
    }
>('jupyter.jupytOperation/optionsEdit', async ({alias, options}, thunkAPI) => {
    const state = thunkAPI.getState();
    const cluster = getCluster(state as RootState);
    const isAdmin = isDeveloper(state);

    const options_to_remove: Array<JupytListAttributes> = [];
    const options_to_set: typeof options = {};

    Object.keys(options).forEach((k) => {
        const key = k as JupytListAttributes;
        if (options[key] === undefined) {
            options_to_remove.push(key);
        } else {
            options_to_set[key] = options[key] as any;
        }
    });

    return jupytApiAction(
        'edit_options',
        cluster,
        {alias, options_to_set, options_to_remove},
        {
            isAdmin,
        },
    ).then(() => {
        thunkAPI.dispatch(jupytOptionsLoad(alias));
        thunkAPI.dispatch(jupytSpecletLoad(alias));
        thunkAPI.dispatch(jupytOperationLoad(alias));
    });
});

export const jupytSpecletClearState = () => {
    return (dispatch: NebiusThunkDispatch) => {
        dispatch(jupytOperationSlice.actions.clearState());
    };
};

export const jupytClearState = () => {
    return (dispatch: NebiusThunkDispatch) => {
        dispatch(jupytSlice.actions.clearJupytState());
    };
};

const JUPYT_STATUS_POLLING_TIMEOUT = 3000; // 3s

export function pollJupytUntilReady({alias}: {alias: string}) {
    return async (_dispatch: NebiusThunkDispatch, getState: any) => {
        const cluster = getCluster(getState());

        const serverSettings = ServerConnection.makeSettings({
            baseUrl: getJupyterBaseUrl({cluster, alias}),
        });

        let kernel = await ServerConnection.makeRequest(
            `${serverSettings.baseUrl}/api/status`,
            {},
            serverSettings,
        );

        while (!kernel.ok) {
            await delay(JUPYT_STATUS_POLLING_TIMEOUT);

            kernel = await ServerConnection.makeRequest(
                `${serverSettings.baseUrl}/api/status`,
                {},
                serverSettings,
            );
        }
    };
}
