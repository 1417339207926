import React, {useCallback, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {RouteComponentProps, useHistory} from 'react-router';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {useUpdater} from '@ytsaurus-ui-platform/src/ui/hooks/use-updater';
import {AccessContentAcl} from '@ytsaurus-ui-platform/src/ui/containers/ACL';
import {
    jupytOperationLoad,
    jupytSpecletClearState,
    removeJupyt,
    startJupyt,
    stopJupyt,
} from '../../store/actions/jupyt';
import {getJupytOperation} from '../../store/selectors/jupytOperation';
import {useNebiusDispatch} from '../../../../store/nebius-dispatch';
import {
    JupytOperationPage,
    JupytOperationPageLoader,
} from '../../components/JupytOperationPage/JupytOperationPage';
import {JUPYT_OPERATIONS_PAGE_ID} from '../../constants';
import {JupytPageOperationTabs} from '../../components/JupytOperationPage/JupytPageOperationTabs/JupytPageOperationTabs';
import {JupytPageOperationSpecletContainer} from '../JupytPageOperationSpeclet/JupytPageOperationSpeclet';
import {JupytSecretsTabContainer} from 'features/Jupyter/containers/JupytSecretsTabContainer/JupytSecretsTabContainer';
import {jupytOperationSlice} from 'features/Jupyter/store/slices/jupytOperation';

export const JupytOperationPageContainer = React.memo(
    (props: RouteComponentProps<{alias: string}>) => {
        const dispatch = useNebiusDispatch();
        const history = useHistory();
        const cluster = useSelector(getCluster);
        const jupytOperation = useSelector(getJupytOperation);

        const {alias} = props.match.params;

        const update = React.useCallback(() => {
            dispatch(jupytOperationLoad(alias));
        }, [alias]);

        useUpdater(update);

        const handleStartJupytClick = useCallback(() => {
            return dispatch(startJupyt({alias})).then(() => {
                dispatch(jupytOperationLoad(alias));
            });
        }, [alias]);

        const handleStopJupytClick = useCallback(() => {
            return dispatch(stopJupyt({alias})).then(() => {
                dispatch(jupytOperationLoad(alias));
            });
        }, [alias]);

        const handleRemoveJupytClick = useCallback(() => {
            return dispatch(removeJupyt({alias})).then(() => {
                history.push(`/${cluster}/${JUPYT_OPERATIONS_PAGE_ID}`);
            });
        }, [alias]);

        useEffect(() => {
            dispatch(jupytOperationSlice.actions.setAlias({alias}));

            return () => {
                dispatch(jupytOperationSlice.actions.setAlias({alias: ''}));
            };
        }, [alias]);

        useEffect(() => {
            return () => {
                dispatch(jupytSpecletClearState());
            };
        }, []);

        const renderSpecletTab = useMemo(() => {
            return () => <JupytPageOperationSpecletContainer alias={alias} />;
        }, [alias]);

        const renderACLTab = useMemo(() => {
            return () => (
                <AccessContentAcl path={`//sys/access_control_object_namespaces/jupyt/${alias}`} />
            );
        }, [alias]);

        const renderSecretsTab = useMemo(() => {
            return () => <JupytSecretsTabContainer alias={alias} />;
        }, [alias]);

        const isLoading = !jupytOperation.data && !jupytOperation.error;

        if (isLoading) {
            return <JupytOperationPageLoader />;
        }

        return (
            <JupytOperationPage
                alias={alias}
                cluster={cluster}
                operation={jupytOperation.data!}
                onStopClick={handleStopJupytClick}
                onStartClick={handleStartJupytClick}
                onRemoveClick={handleRemoveJupytClick}
                tabs={
                    <JupytPageOperationTabs
                        renderSpecletTab={renderSpecletTab}
                        renderACLTab={renderACLTab}
                        renderSecretsTab={renderSecretsTab}
                    />
                }
            />
        );
    },
);
