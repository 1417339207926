import React from 'react';
import {Route, Switch} from 'react-router';

import ErrorBoundary from '@ytsaurus-ui-platform/src/ui/components/ErrorBoundary/ErrorBoundary';

import {JupytListPageContainer} from '../JupytListPageContainer/JupytListPageContainer';
import {JupytOperationPageContainer} from '../JupytOperationPage/JupytOperationPage';
import {JUPYT_OPERATIONS_PAGE_ID} from '../../constants';
import {KernelNotebookSaveDialog} from '../Dialogs/NotebookSaveDialog/NotebookSaveDialog';

function renderModals() {
    return (
        <>
            <KernelNotebookSaveDialog />
        </>
    );
}

export default function JupytPage() {
    return (
        <ErrorBoundary>
            <Switch>
                <Route
                    path={`/:cluster/${JUPYT_OPERATIONS_PAGE_ID}/:alias`}
                    component={JupytOperationPageContainer}
                />
                <Route
                    path={`/:cluster/${JUPYT_OPERATIONS_PAGE_ID}`}
                    component={JupytListPageContainer}
                />
            </Switch>
            {renderModals()}
        </ErrorBoundary>
    );
}
