import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {FetchResourcesUsageAndLimitsResponse} from 'features/UniverseResources/api/resources';
import type {PoolTreesListType} from 'features/UniverseResources/types/resources';

export const enum ResourceType {
    universe = 'universe',
    global = 'global',
}

type ResourcesInitialState = {
    isUniversePoolExist: boolean | undefined;
    visibleResourceType: ResourceType;
    poolTreesList: PoolTreesListType;
    poolTreeResources: FetchResourcesUsageAndLimitsResponse | undefined;
};

const initialState: ResourcesInitialState = {
    isUniversePoolExist: undefined,
    visibleResourceType: ResourceType.global,
    poolTreesList: [],
    poolTreeResources: undefined,
};

export const resourcesSlice = createSlice({
    name: 'universe-resources',
    initialState,
    reducers: {
        setIsUniversePoolExist: (state, action: PayloadAction<{value: boolean}>) => {
            state.isUniversePoolExist = action.payload.value;
        },
        setVisibleResourceType: (state, action: PayloadAction<{value: ResourceType}>) => {
            state.visibleResourceType = action.payload.value;
        },
        setPoolTreesList: (state, action: PayloadAction<{value: PoolTreesListType}>) => {
            state.poolTreesList = action.payload.value;
        },
        setPoolTreeResources: (
            state,
            action: PayloadAction<{value: FetchResourcesUsageAndLimitsResponse | undefined}>,
        ) => {
            state.poolTreeResources = action.payload.value;
        },
    },
});
