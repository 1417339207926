import type {INotebookContent} from '@jupyterlab/nbformat';
import {type Draft, produce} from 'immer';

export const mapNotebookToLatestVersion = (notebook: INotebookContent): INotebookContent => {
    return produce(notebook, (notebookDraft: Draft<INotebookContent>) => {
        createCellIdIfMissed(notebookDraft);
    });
};

const createCellIdIfMissed = (notebookDraft: Draft<INotebookContent>) => {
    notebookDraft.cells.forEach((cell) => {
        if (!cell.metadata.cell_id) {
            cell.metadata.cell_id = crypto.randomUUID();
        }
    });
};
