import React, {useRef} from 'react';
import {useHistory} from 'react-router';

const ACTIONS = ['POP', 'PUSH'];

// Safari doesn't support requestIdleCallback, so fe use fallback to setTimeout here.
// Details: https://caniuse.com/?search=requestIdleCallback
const requestIdleCallbackWithFallback = window.requestIdleCallback || setTimeout;

export const useNavigationBlocker = ({shouldListen}: {shouldListen: boolean}) => {
    const history = useHistory();
    const flag = useRef(true);
    const locationMap = useRef(new Set());

    React.useEffect(() => {
        flag.current = true;
        locationMap.current = new Set();

        // @ts-expect-error
        const unblock = history.block((location, action) => {
            const fullPath = `${location.pathname}${location.search}`;

            requestIdleCallbackWithFallback(() => {
                locationMap.current = new Set();
            });

            if (shouldListen && ACTIONS.includes(action)) {
                if (
                    history.location.pathname === location.pathname &&
                    location.search.includes(history.location.search.replace('?', ''))
                ) {
                    return false;
                }

                if (locationMap.current.has(fullPath)) {
                    return false;
                }

                locationMap.current.add(fullPath);

                return window.confirm('Changes you made may not be saved.');
            }

            return true;
        });

        return () => {
            unblock();
        };
    }, [shouldListen, history]);
};
