import React from 'react';
import cn from 'bem-cn-lite';
import {Button, TextInput} from '@gravity-ui/uikit';

import format from '@ytsaurus-ui-platform/src/ui/common/hammer/format';
import {Toolbar} from '@ytsaurus-ui-platform/src/ui/components/WithStickyToolbar/Toolbar/Toolbar';
import {SelectSingle} from '@ytsaurus-ui-platform/src/ui/components/Select/Select';
import ColumnSelectorModal from '@ytsaurus-ui-platform/src/ui/components/ColumnSelectorModal/ColumnSelectorModal';
import Icon from '@ytsaurus-ui-platform/src/ui/components/Icon/Icon';
import {JupyterListFilters} from '../types';

import './JupytListToolbar.scss';

const block = cn('jupyter-list-toolbar');

function JupytListToolbar(props: {
    filters: JupyterListFilters;
    onFiltersUpdate: (filters: Partial<any>) => void;
}) {
    const onUpdate = React.useCallback(
        (filters: Partial<any>) => {
            props.onFiltersUpdate(filters);
        },
        [props.onFiltersUpdate],
    );

    return (
        <Toolbar
            className={block()}
            itemsToWrap={[
                {
                    name: 'alias',
                    node: <NameIdFilter onUpdate={onUpdate} state={props.filters.alias} />,
                    growable: true,
                    wrapperClassName: block('name-filter'),
                },
                {
                    name: 'creator',
                    node: <CreatorFilter onUpdate={onUpdate} state={props.filters.creator} />,
                },
                {
                    name: 'state',
                    node: <StateFilter onUpdate={onUpdate} state={props.filters.state} />,
                },
                {
                    name: 'health',
                    node: <HealthFilter onUpdate={onUpdate} state={props.filters.health} />,
                },
                {
                    name: 'columns',
                    node: <ColumnsFilter state={props.filters.columns} onUpdate={onUpdate} />,
                },
            ]}
        />
    );
}

function NameIdFilter({
    onUpdate,
    state,
}: {
    state: JupyterListFilters['alias'];
    onUpdate: (value: {alias: string}) => void;
}) {
    return (
        <TextInput
            value={state.value}
            onUpdate={(alias) => {
                onUpdate({alias});
            }}
            placeholder="Filter by kernel alias or id..."
        />
    );
}

function CreatorFilter({
    onUpdate,
    state,
}: {
    state: JupyterListFilters['creator'];
    onUpdate: (value: {creator?: string}) => void;
}) {
    const items = React.useMemo(() => {
        return state.options.map((item) => {
            return {
                value: item,
                text: item,
            };
        });
    }, [state.options]);

    return (
        <SelectSingle
            hasClear
            popupPlacement="top"
            className={block('select-filter')}
            label="Creator:"
            value={state.value}
            items={items}
            onChange={(creator) => {
                onUpdate({creator});
            }}
        />
    );
}

function HealthFilter({
    onUpdate,
    state,
}: {
    state: JupyterListFilters['health'];
    onUpdate: (value: {health?: string}) => void;
}) {
    const items = React.useMemo(() => {
        return state.options.map((item) => {
            return {
                value: item,
                text: item,
            };
        });
    }, [state.options]);

    return (
        <SelectSingle
            hasClear
            popupPlacement="top"
            className={block('select-filter')}
            label="Health:"
            value={state.value}
            items={items}
            onChange={(health) => {
                onUpdate({health});
            }}
        />
    );
}

function StateFilter({
    onUpdate,
    state,
}: {
    state: JupyterListFilters['state'];
    onUpdate: (value: {state?: string}) => void;
}) {
    const items = React.useMemo(() => {
        return state.options.map((item) => {
            return {
                value: item,
                text: item,
            };
        });
    }, [state.options]);
    return (
        <SelectSingle
            hasClear
            popupPlacement="top"
            className={block('select-filter')}
            label="State:"
            value={state.value}
            items={items}
            onChange={(state) => {
                onUpdate({state});
            }}
        />
    );
}

function ColumnsFilter({
    onUpdate,
    state,
}: {
    onUpdate: (value: {columns: unknown[]}) => void;
    state: JupyterListFilters['columns'];
}) {
    const [visible, setVisible] = React.useState(false);

    // We do need useRef here, because otherwise the
    // "state" will trigget rerender and it's will change ColumnSelectorModal content
    const items = React.useRef(
        state.values.map((i) => {
            return {
                ...i,
                // eslint-disable-next-line new-cap
                name: i.name ?? format.ReadableField(i.name),
            };
        }),
    );

    const dialog = (
        <ColumnSelectorModal
            isVisible={visible}
            items={items.current}
            onConfirm={(value) => {
                onUpdate({columns: value});
                setVisible(false);
            }}
            onCancel={() => setVisible(false)}
        />
    );

    return (
        <React.Fragment>
            {visible && dialog}
            <Button view="outlined" onClick={() => setVisible(true)}>
                <Icon awesome="layout-columns-3" />
                Columns
            </Button>
        </React.Fragment>
    );
}

export default React.memo(JupytListToolbar);
