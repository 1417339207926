import {NebiusRootState} from 'store/reducers';

export const selectIsUniversePoolExist = (state: NebiusRootState) =>
    state.nebius.universeResources.resources.isUniversePoolExist;

export const selectVisibleResourceType = (state: NebiusRootState) =>
    state.nebius.universeResources.resources.visibleResourceType;

export const selectPoolTreesList = (state: NebiusRootState) =>
    state.nebius.universeResources.resources.poolTreesList;

export const selectPoolTreeResources = (state: NebiusRootState) =>
    state.nebius.universeResources.resources.poolTreeResources;
