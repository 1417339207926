import React from 'react';
import {JupyterStatus} from 'features/Jupyter/components/JupytSelect/JupyterStatus/JupyterStatus';
import {useSelector} from 'react-redux';
import {selectJupyterKernelStatus} from 'features/Jupyter/store/selectors/notebook';

export const JupyterStatusContainer = () => {
    const status = useSelector(selectJupyterKernelStatus);

    return <JupyterStatus status={status} />;
};
