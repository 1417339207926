import {combineReducers} from '@reduxjs/toolkit';
import {RootState} from '@ytsaurus-ui-platform/src/ui/store/reducers';
import {jupyterRoot} from '../features/Jupyter/store/slices';
import {navigationRoot} from '../features/Navigation/store/slices';
import {universeResourcesRoot} from 'features/UniverseResources/store/slices';

export const nebiusAppReducers = {
    nebius: combineReducers({
        jupyter: jupyterRoot,
        navigation: navigationRoot,
        universeResources: universeResourcesRoot,
    }),
};

export type NebiusRootState = RootState & {
    nebius: ReturnType<(typeof nebiusAppReducers)['nebius']>;
};
