import React from 'react';
import {ExtraTab, UIFactoryClusterPageInfo} from '@ytsaurus-ui-platform/src/ui/UIFactory';
import {JupyterView} from 'features/Jupyter/containers/JupyterView/JupyterView';
import {Tab as NavigationTab} from '@ytsaurus-ui-platform/src/ui/constants/navigation/index';
import type {Node} from '@ytsaurus-ui-platform/src/ui/utils/navigation/content/map-nodes/node';
import {getUserAttributeKeys} from '../../utils/attributes-selectors';
import {getNebiusConfigData} from 'config/ui-config';
import {isEnvTrue} from '../../../shared/utils/env';
import {IS_JUPYTER_NOTEBOOK, JUPYT_OPERATIONS_PAGE_ID} from 'features/Jupyter/constants';
import JupytPage from './containers/JupytPage/JupytPage';
import {JupytListPageTopRowContainer} from './containers/JupytListPageTopRowContainer/JupytListPageTopRowContainer';
import {Icon} from '@gravity-ui/uikit';
import {LogoPython} from '@gravity-ui/icons';
import {setupMonacoJupyterExtensions} from './lib/monaco-jupyter/extensions';
import {selectIsNotebookSaved, selectIsSolutionNotebook} from './store/selectors/notebook';

setupMonacoJupyterExtensions();

export const JUPYTER_NOTEBOOK_NAVIGATION_TAB: ExtraTab = {
    value: `extra_notebook`,
    text: 'Notebook',
    title: 'Go to notebook interface',
    component: JupyterView,
    position: {before: NavigationTab.CONTENT},
    isSupported: (attributes) => {
        const keys = getUserAttributeKeys(attributes);

        return (
            keys.has(IS_JUPYTER_NOTEBOOK) &&
            isEnvTrue(getNebiusConfigData().env.SHOW_JUPYT_INTERFACE)
        );
    },
    canLeaveTab: async () => {
        const state = window.store.getState();

        if (!selectIsNotebookSaved(state) && !selectIsSolutionNotebook(state)) {
            return window.confirm('Changes you made may not be saved.');
        }

        return true;
    },
};

export const JUPYT_OPERATIONS_PAGE: UIFactoryClusterPageInfo = {
    title: 'Kernels',
    pageId: JUPYT_OPERATIONS_PAGE_ID,
    svgIcon: LogoPython,
    reactComponent: JupytPage,
    topRowComponent: JupytListPageTopRowContainer,
};

export const JUPYTER_NAVIGATION_MAP_NODE_SETTINGS = {
    additionalAttributes: [IS_JUPYTER_NOTEBOOK],
    renderNodeIcon: (item: Node) => {
        if (item.$attributes?.[IS_JUPYTER_NOTEBOOK]) {
            return <Icon data={LogoPython} size={14} />;
        }

        return null;
    },
};
