import {Action, ActionReducerMapBuilder, PayloadAction, createSlice} from '@reduxjs/toolkit';
import {YTError} from '@ytsaurus-ui-platform/src/@types/types';
import {JupytDescribeOptionsType, JupytStatusResponse} from 'features/Jupyter/api/jupyt';
import {jupytOperationLoad, jupytOptionsLoad, jupytSpecletLoad} from '../actions/jupyt';

export type JupytOperationState = {
    alias: string;
    operation: {
        data?: JupytStatusResponse;
        error?: YTError;
    };
    speclet: {
        data?: unknown;
        error?: YTError;
    };
    options: {
        data?: JupytDescribeOptionsType;
        error?: YTError;
    };
};

const initialState: JupytOperationState = {
    alias: '',
    operation: {},
    speclet: {},
    options: {},
};

interface RejectedAction extends Action {
    error: YTError;
}

function isRejectedAction(action: Action): action is RejectedAction {
    return action.type.endsWith('rejected');
}

export const jupytOperationSlice = createSlice({
    name: 'jupyter.jupytOperation',
    initialState,
    reducers: {
        clearState: () => initialState,
        setAlias: (state, action: PayloadAction<{alias: string}>) => {
            state.alias = action.payload.alias;
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<JupytOperationState>) => {
        builder
            .addCase(jupytOperationLoad.pending, (state) => {
                state.operation.error = undefined;
            })
            .addCase(jupytOperationLoad.fulfilled, (state, action) => {
                if (action.payload?.result) {
                    state.operation.data = action.payload.result;
                }
            })
            .addCase(jupytOperationLoad.rejected, (state, action) => {
                if (isRejectedAction(action)) {
                    state.operation.error = action.error;
                }
            });

        builder
            .addCase(jupytSpecletLoad.pending, (state) => {
                state.speclet.error = undefined;
            })
            .addCase(jupytSpecletLoad.fulfilled, (state, action) => {
                if (action.payload?.result) {
                    state.speclet.data = action.payload.result;
                }
            })
            .addCase(jupytSpecletLoad.rejected, (state, action) => {
                if (isRejectedAction(action)) {
                    state.speclet.error = action.error;
                }
            });

        builder
            .addCase(jupytOptionsLoad.pending, (state) => {
                state.options.error = undefined;
            })
            .addCase(jupytOptionsLoad.fulfilled, (state, action) => {
                if (action.payload?.result) {
                    state.options.data = action.payload?.result;
                }
            })
            .addCase(jupytOptionsLoad.rejected, (state, action) => {
                if (isRejectedAction(action)) {
                    state.options.error = action.error;
                }
            });
    },
});
