import React from 'react';

import block from 'bem-cn-lite';
import {Label} from '@gravity-ui/uikit';
import type {Kernel} from '@jupyterlab/services';

import './JupyterStatus.scss';

const b = block('jupyter-status');

type JupyterStatusProps = {
    status: Kernel.Status;
};

const THEME_MAP: Record<Kernel.Status, LabelTheme> = {
    idle: 'success',
    busy: 'warning',
    unknown: 'unknown',
    starting: 'info',
    terminating: 'info',
    restarting: 'info',
    autorestarting: 'info',
    dead: 'danger',
};

type LabelTheme =
    | 'normal'
    | 'info'
    | 'danger'
    | 'warning'
    | 'success'
    | 'utility'
    | 'unknown'
    | 'clear';

export const JupyterStatus: React.FC<JupyterStatusProps> = ({status}: JupyterStatusProps) => {
    const theme = THEME_MAP[status] || 'info';
    return (
        <Label size={'m'} className={b()} theme={theme}>
            {status}
        </Label>
    );
};
