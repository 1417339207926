import {ResourcesComponent} from 'features/UniverseResources/components/ResourcesComponent/ResourcesComponent';
import React from 'react';
import _chunk from 'lodash/chunk';
import {
    mapPoolTreeToHint,
    mapPoolTreeToTitle,
    mapResourcesToResourceEntry,
    prepareDiskResources,
} from 'features/UniverseResources/mappers/resources';
import {useSelector} from 'react-redux';
import {getMediumList} from '@ytsaurus-ui-platform/src/ui/store/selectors/thor';
import {
    selectPoolTreeResources,
    selectPoolTreesList,
    selectVisibleResourceType,
} from 'features/UniverseResources/store/selectors/resources';

type ResourcesContainerProps = {
    opensourceRenderImpl: () => React.ReactNode;
};

export const ResourcesContainer = (props: ResourcesContainerProps) => {
    const visibleResourceType = useSelector(selectVisibleResourceType);
    const poolTreesList = useSelector(selectPoolTreesList);
    const poolTreesResources = useSelector(selectPoolTreeResources);
    const mediumList = useSelector(getMediumList);

    if (visibleResourceType === 'global') {
        return props.opensourceRenderImpl();
    }

    let diskResources = null;

    const universeDiskResource = poolTreesResources?.universeDiskResource;

    if (universeDiskResource) {
        const entries = prepareDiskResources({
            availableSpacePerMedium: universeDiskResource.resource_limits.disk_space_per_medium,
            usedSpacePerMedium: universeDiskResource.resource_usage.disk_space_per_medium,
            mediumList,
        });
        const title = mapPoolTreeToTitle('universe', {});
        const hint = mapPoolTreeToHint('universe', {});
        diskResources = <ResourcesComponent title={title} hint={hint} entries={entries} />;
    }

    return (
        <>
            {poolTreesList.map((poolTree: string) => {
                if (!poolTreesResources) {
                    return null;
                }

                const resources = poolTreesResources.universeResourceByPoolTree[poolTree];
                const entries = mapResourcesToResourceEntry({
                    poolTree,
                    resources,
                });

                const title = mapPoolTreeToTitle(poolTree, resources.ui_config);
                const hint = mapPoolTreeToHint(poolTree, resources.ui_config);

                return <ResourcesComponent title={title} hint={hint} entries={entries} />;
            })}
            {diskResources}
        </>
    );
};
