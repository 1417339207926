import * as React from 'react';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import cn from 'bem-cn-lite';
import {KernelPageQa} from '../../../../../shared/qa';

const block = cn('jupyt-list-page-top-row');

import './JupytListPageTopRow.scss';

export const JupytListPageTopRow: React.FC<{
    onCreateNotebookClick: () => void;
    onCreateKernelClick: () => void;
}> = (props) => {
    return (
        <>
            <div className={block()}>
                <Button
                    onClick={props.onCreateKernelClick}
                    qa={KernelPageQa.KernelPageCreateKernelBtn}
                >
                    Create kernel
                </Button>

                <Button
                    onClick={props.onCreateNotebookClick}
                    qa={KernelPageQa.KernelPageCreateNotebookBtn}
                >
                    Create notebook
                </Button>
            </div>
        </>
    );
};
