import React from 'react';
import {Button, DropdownMenu, DropdownMenuItem} from '@gravity-ui/uikit';
import Icon from '@ytsaurus-ui-platform/src/ui/components/Icon/Icon';
import {KernelPageQa} from '../../../../../../../shared/qa';

export function JupytListTableActions({
    onRemoveClick,
    onStartClick,
    onStopClick,
}: {
    onRemoveClick: () => void;
    onStartClick: () => void;
    onStopClick: () => void;
}) {
    const start = {
        icon: <Icon awesome="play-circle" />,
        text: 'Start',
        action: () => {
            onStartClick();
        },
    };
    const stop = {
        icon: <Icon awesome="stop-circle" />,
        text: 'Stop',
        action: () => {
            onStopClick();
        },
    };
    const remove = {
        icon: <Icon awesome="trash-alt" />,
        text: 'Remove',
        action: () => {
            onRemoveClick();
        },
    };

    const menuItems: Array<Array<DropdownMenuItem>> = [[start, stop], [remove]];

    return (
        <DropdownMenu
            popupProps={{
                qa: KernelPageQa.KernelPageActionsPopup,
            }}
            switcher={
                <Button view={'flat'} qa={KernelPageQa.KernelPageActions}>
                    <Icon awesome="ellipsis-h" />
                </Button>
            }
            items={menuItems}
        />
    );
}
