import React from 'react';
import block from 'bem-cn-lite';
import * as nbformat from '@jupyterlab/nbformat';
import {Icon, QAProps, Select} from '@gravity-ui/uikit';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {ArrowDown, ArrowUp, Play, Stop, TrashBin} from '@gravity-ui/icons';
import './JupyterCell.scss';
import {JupyterCellSource} from '../JupyterCellSource/JupyterCellSource';
import {JupyterCellOutput} from '../JupyterCellOutput/JupyterCellOutput';
import {CellType} from 'features/Jupyter/types';
import {JupyterCellQa} from '../../../../../shared/qa';

const ACTIONS_CONTAINER_ICON_SIZE = 12;
const ACTIONS_CONTAINER_CONTROL_SIZE = 's';
const b = block('jupyter-cell');

export type JupyterCellProps = {
    isEditable?: boolean;
    isFocused?: boolean;
    isDirty?: boolean;
    cell: nbformat.ICell;
    isRunning?: boolean;
    onChange: (value: string) => void;
    onRunClick?: () => void;
    onCancelClick?: () => void;
    onArrowUpClick?: () => void;
    onArrowDownClick?: () => void;
    onTrashBinClick?: () => void;
    onClick?: () => void;
    onKeyDown?: (node: HTMLDivElement | null) => (event: React.KeyboardEvent<HTMLElement>) => void;
    onEditorFocus?: (event: React.FocusEvent<HTMLElement>) => void;
    onDoubleClick?: () => void;
    onMarkdownBlur?: () => void;
    onChangeCellType?: (value: string[]) => void;
    onImagePaste?: (value: {name: string; type: string; base64: string}) => void;
};

export const JupyterCell: React.FC<JupyterCellProps> = ({
    isDirty,
    isEditable,
    isFocused,
    cell,
    isRunning,
    onChange,
    onRunClick,
    onCancelClick,
    onArrowUpClick,
    onArrowDownClick,
    onTrashBinClick,
    onKeyDown,
    onEditorFocus,
    onDoubleClick,
    onMarkdownBlur,
    onChangeCellType,
    onImagePaste,
    onClick,
}: JupyterCellProps) => {
    const containerRef = React.useRef<HTMLDivElement>(null);

    const isMarkdown = nbformat.isMarkdown(cell);
    const isCode = nbformat.isCode(cell);

    const cellContainsOutput = isCode ? cell.outputs.length : false;

    React.useEffect(() => {
        if (isFocused && !isEditable) {
            containerRef.current?.focus({preventScroll: true});
        }
    }, [isFocused, isEditable]);

    const handleOnKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLElement>) => {
            onKeyDown?.(containerRef.current)(event);
        },
        [onKeyDown],
    );

    const handleActionContainerClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    }, []);

    return (
        <div
            data-qa={JupyterCellQa.JupyterCell}
            tabIndex={-1}
            data-id={cell.metadata.cell_id}
            className={b({focused: isFocused, markdown: isMarkdown})}
            ref={containerRef}
            onKeyDown={handleOnKeyDown}
            onDoubleClick={onDoubleClick}
            onClick={onClick}
        >
            <div
                onClick={handleActionContainerClick}
                className={b('actions-container', {
                    markdown: isMarkdown,
                    code: isCode,
                    editable: isEditable,
                })}
            >
                <ExecuteButton
                    qa={JupyterCellQa.JupyterCellExecuteButton}
                    cell={cell}
                    onRunClick={onRunClick}
                    onCancelClick={onCancelClick}
                    isRunning={isRunning}
                />
                <Select<CellType>
                    qa={JupyterCellQa.JupyterCellTypeSelect}
                    className={b('action')}
                    value={cell.cell_type ? [cell.cell_type] : []}
                    onUpdate={onChangeCellType}
                    size="s"
                    width={110}
                >
                    <Select.Option qa={JupyterCellQa.JupyterCellTypeCode} value="code">
                        Code
                    </Select.Option>
                    <Select.Option qa={JupyterCellQa.JupyterCellTypeMarkdown} value="markdown">
                        Markdown
                    </Select.Option>
                </Select>
                <Button
                    qa={JupyterCellQa.JupyterCellMoveUpButton}
                    className={b('action')}
                    view="flat-secondary"
                    onClick={onArrowUpClick}
                    withTooltip
                    size={ACTIONS_CONTAINER_CONTROL_SIZE}
                    tooltipProps={{
                        content: 'Move this cell up',
                        placement: 'top',
                    }}
                >
                    <Icon data={ArrowUp} size={ACTIONS_CONTAINER_ICON_SIZE} />
                </Button>
                <Button
                    qa={JupyterCellQa.JupyterCellMoveDownButton}
                    className={b('action')}
                    view="flat-secondary"
                    onClick={onArrowDownClick}
                    withTooltip
                    size={ACTIONS_CONTAINER_CONTROL_SIZE}
                    tooltipProps={{
                        content: 'Move this cell down',
                        placement: 'top',
                    }}
                >
                    <Icon data={ArrowDown} size={ACTIONS_CONTAINER_ICON_SIZE} />
                </Button>
                <Button
                    qa={JupyterCellQa.JupyterCellDeleteButton}
                    className={b('action')}
                    view="flat-secondary"
                    onClick={onTrashBinClick}
                    size={ACTIONS_CONTAINER_CONTROL_SIZE}
                    withTooltip
                    tooltipProps={{
                        content: 'Delete cell',
                        placement: 'top',
                    }}
                >
                    <Icon data={TrashBin} size={ACTIONS_CONTAINER_ICON_SIZE} />
                </Button>
            </div>
            <div className={b('source')}>
                <JupyterCellSource
                    cell={cell}
                    isDirty={isDirty}
                    isEditable={isEditable}
                    isRunning={isRunning}
                    isFocused={isFocused}
                    onChange={onChange}
                    onFocus={onEditorFocus}
                    onMarkdownBlur={onMarkdownBlur}
                    onImagePaste={onImagePaste}
                />
            </div>
            {cellContainsOutput ? (
                <div className={b('output')}>
                    <JupyterCellOutput cell={cell} />
                </div>
            ) : null}
        </div>
    );
};

type ExecuteButtonProps = {
    cell: nbformat.ICell;
    isRunning?: boolean;
    onRunClick?: () => void;
    onCancelClick?: () => void;
};

function ExecuteButton({
    isRunning,
    cell,
    onRunClick,
    onCancelClick,
    qa,
}: ExecuteButtonProps & QAProps) {
    if (!nbformat.isCode(cell)) {
        return null;
    }

    const icon = isRunning ? Stop : Play;
    const onClick = isRunning
        ? () => {
              if (onCancelClick) {
                  onCancelClick();
              }
          }
        : onRunClick;

    return (
        <Button
            qa={qa}
            size={ACTIONS_CONTAINER_CONTROL_SIZE}
            view="flat"
            onClick={onClick}
            selected={isRunning}
        >
            <Icon data={icon} size={ACTIONS_CONTAINER_ICON_SIZE} />
        </Button>
    );
}
