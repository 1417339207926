export enum JupyterCellQa {
    JupyterCell = 'JupyterCell',
    JupyterCellExecuteButton = 'JupyterCellExecuteButton',
    JupyterCellMoveUpButton = 'JupyterCellMoveUpButton',
    JupyterCellMoveDownButton = 'JupyterCellMoveDownButton',
    JupyterCellDeleteButton = 'JupyterCellDeleteButton',
    JupyterCellTypeSelect = 'JupyterCellTypeSelect',
    JupyterCellOutput = 'JupyterCellOutput',
    JupyterCellSource = 'JupyterCellSource',
    JupyterCellSourceHeader = 'JupyterCellSourceHeader',
    JupyterCellTypeCode = 'JupyterCellTypeCode',
    JupyterCellTypeMarkdown = 'JupyterCellTypeMarkdown',
}

export enum NotebookPageQa {
    PlaceholderAddCellButton = 'PlaceholderAddCellButton',
    AddCellSeparator = 'AddCellSeparator',
    KernelSelect = 'KernelSelect',
    ContentGrid = 'ContentGrid',
    KernelSelectText = 'KernelSelectText',
}

export enum KernelPageQa {
    KernelsList = 'KernelsList',
    KernelPageTable = 'KernelPageTable',
    KernelPageCreateKernelBtn = 'KernelPageCreateKernelBtn',
    KernelPageCreateNotebookBtn = 'KernelPageCreateNotebookBtn',
    KernelPageSpecletInfo = 'KernelPageSpecletInfo',
    KernelPageActions = 'KernelPageActions',
    KernelPageActionsPopup = 'KernelPageActionsPopup',
}
