import React from 'react';

import block from 'bem-cn-lite';
import {Flex, Text} from '@gravity-ui/uikit';

import './JupytSelectOptions.scss';
import {JupytStateIcon} from '../JupytStateIcon/JupytStateIcon';
import type {JupytServerHealthType, JupytServerStateType} from 'features/Jupyter/api/jupyt';

const b = block('jupyt-select-option');

type JupytSelectOptionProps = {
    value: string;
    status?: JupytServerHealthType | JupytServerStateType;
    qa?: string;
};

export const JupytSelectOption: React.FC<JupytSelectOptionProps> = (
    props: JupytSelectOptionProps,
) => {
    const {value, status, qa} = props;

    return (
        <Flex title={value} justifyContent={'space-between'} className={b()}>
            <Text className={b('text')} variant="body-1" ellipsis={true} qa={qa}>
                {value}
            </Text>
            {status ? <JupytStateIcon className={b('icon')} status={status} /> : null}
        </Flex>
    );
};
