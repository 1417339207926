import React from 'react';
import {UIFactory} from '@ytsaurus-ui-platform/src/ui/UIFactory';
import {defaultUIFactory} from '@ytsaurus-ui-platform/src/ui/UIFactory/default-ui-factory';
import type {AppNavigationProps} from '@ytsaurus-ui-platform/src/ui/containers/AppNavigation/AppNavigationPageLayout';
import {NebiusSubjectCard} from '../components/NebiusSubjectCard/NebiusSubjectCard';
import {nebiusAppReducers} from '../store/reducers';
import {
    JUPYTER_NAVIGATION_MAP_NODE_SETTINGS,
    JUPYTER_NOTEBOOK_NAVIGATION_TAB,
    JUPYT_OPERATIONS_PAGE,
} from 'features/Jupyter';
import {monitoring} from 'features/Monitoring';
import {AppNavigationPageLayout} from '../features/Navigation';
import type {DropdownMenuItem} from '@gravity-ui/uikit';
import {NavigationNotebookSaveDialog} from '../features/Jupyter/containers/Dialogs/NotebookSaveDialog/NotebookSaveDialog';
import {getNebiusMapNodeToolbarCreateActions} from '../pages/navigation/MapNode/utils/dropdown-actions';
import {isEnvTrue} from '../../shared/utils/env';
import {getNebiusConfigData, isProductionEnvironment} from '../config/ui-config';
import {DocumentCreateDialog} from '../features/Navigation/containers/Dialogs/DocumentCreateDialogs';
import {ResourcesContainer, ResourcesHOC, ResourcesTypeSwitch} from 'features/UniverseResources';

const TractoResources = {
    ResourcesContainer,
    ResourcesHOC,
    ResourcesTypeSwitch,
};

interface TractoUIFactory extends UIFactory {
    TractoResources: typeof TractoResources;
}

const productionOverrides: Partial<TractoUIFactory> = {
    renderSubjectCard(props) {
        return <NebiusSubjectCard {...props} />;
    },

    getExtraReducersAndUrlMapping() {
        return {
            reducers: nebiusAppReducers,
        };
    },
    renderAppNavigation(props: AppNavigationProps): React.ReactElement {
        return <AppNavigationPageLayout {...props} />;
    },

    getMapNodeExtraCreateActions(baseActions: Array<DropdownMenuItem>): {
        menuItems: Array<DropdownMenuItem>;
        renderModals: () => React.ReactNode;
    } {
        return {
            menuItems: getNebiusMapNodeToolbarCreateActions(baseActions),
            renderModals: () => (
                <>
                    <NavigationNotebookSaveDialog />
                    <DocumentCreateDialog />
                </>
            ),
        };
    },

    getNavigationMapNodeSettings() {
        return JUPYTER_NAVIGATION_MAP_NODE_SETTINGS;
    },

    TractoResources,
};

let developmentOverrides: Partial<TractoUIFactory> = {};

if (!isProductionEnvironment()) {
    developmentOverrides = {
        ...developmentOverrides,
        ...monitoring,
    };
}

if (isEnvTrue(getNebiusConfigData().env.SHOW_POOL_DASHBOARD)) {
    developmentOverrides = {
        ...developmentOverrides,
        getSchedulingExtraTabs: monitoring.getSchedulingExtraTabs,
    };
}

if (isEnvTrue(getNebiusConfigData().env.SHOW_JUPYT_INTERFACE)) {
    developmentOverrides = {
        ...developmentOverrides,
        getNavigationExtraTabs() {
            return [JUPYTER_NOTEBOOK_NAVIGATION_TAB];
        },
        getExtaClusterPages() {
            return [JUPYT_OPERATIONS_PAGE];
        },
    };
}

export const tractoUIFactory: UIFactory = {
    ...defaultUIFactory,
    ...productionOverrides,
    ...developmentOverrides,
};
