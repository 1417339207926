import React, {useCallback, useEffect} from 'react';
import block from 'bem-cn-lite';
import {JupyterGridContainer} from 'features/Jupyter/containers/JupyterGridContainer/JupyterGridContainer';
import {JupytOperationsListContainer} from 'features/Jupyter/containers/JupytOperationsListContainer/JupytOperationsListContainer';
import {useNebiusDispatch} from '../../../../store/nebius-dispatch';
import {
    jupytClearState,
    loadJupytDefaults,
    loadNotebookJupytList,
    setInitialJupytAlias,
} from '../../store/actions/jupyt';
import WithStickyToolbar from '@ytsaurus-ui-platform/src/ui/components/WithStickyToolbar/WithStickyToolbar';
import {DEFAULT_UPDATER_TIMEOUT} from '@ytsaurus-ui-platform/src/ui/hooks/use-updater';
import {batch, useSelector} from 'react-redux';
import {NebiusRootState} from 'store/reducers';
import {selectNotebookContent} from 'features/Jupyter/store/selectors/notebook';
import {Loader} from '@gravity-ui/uikit';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {
    clearNotebookState,
    loadNotebookContent,
    setNotebookContent,
} from '../../store/actions/notebook';
import {CopySolutionNotebookDialog} from '../Dialogs/CopySolutionNotebookDialog/CopySolutionNotebookDialog';
import {JupytCreateDialog} from '../Dialogs/JupytCreateDialog/JupytCreateDialog';

import './JupyterView.scss';
import {JupyterApi} from '../../api/kernel';
import {NebiusLogger} from '../../../../utils/logging';
import throttle from 'lodash/throttle';
import type nbformat from '@jupyterlab/nbformat';
import nebiusLocalStorage, {storageKey} from 'utils/nebius-local-storage';
import {CommandPaletteContainer} from '../CommandPaletteContainer/CommandPaletteContainer';

const b = block('jupyter-view');

export const JupyterView = () => {
    const dispatch = useNebiusDispatch();
    const path: string = useSelector(getPath);
    const notebookContent = useSelector(selectNotebookContent);

    const onLocalStorageEventHandler = useCallback((event: StorageEvent) => {
        if (event.key === storageKey('notebooks')) {
            const content = nebiusLocalStorage.getNotebook(path)?.content;

            if (content) {
                dispatch(setNotebookContent(content));
            }
        }
    }, []);

    useEffect(() => {
        Promise.all([
            dispatch(loadNotebookContent(path)).unwrap(),
            dispatch(loadNotebookJupytList()),
        ]).then(() => {
            dispatch(setInitialJupytAlias());
        });

        dispatch(loadJupytDefaults());

        const intervalId = setInterval(() => {
            dispatch(loadNotebookJupytList());
        }, DEFAULT_UPDATER_TIMEOUT);

        window.addEventListener('storage', onLocalStorageEventHandler);

        return () => {
            window.removeEventListener('storage', onLocalStorageEventHandler);
            nebiusLocalStorage.storeNotebook({
                id: path,
                content: undefined,
            });
            batch(async () => {
                clearInterval(intervalId);
                dispatch(clearNotebookState());
                dispatch(jupytClearState());

                await JupyterApi.disposeConnection();
                NebiusLogger.log('Dispose jupyter connection, when page leave - Done');
            });
        };
    }, []);

    const throttleStoreNotebook = React.useCallback(
        throttle((notebookContent: nbformat.INotebookContent) => {
            nebiusLocalStorage.storeNotebook({
                id: path,
                content: notebookContent,
            });
        }, 1000),
        [],
    );

    React.useEffect(() => {
        if (notebookContent) {
            throttleStoreNotebook(notebookContent);
        }
    }, [notebookContent]);

    const isNotebookLoaded = useSelector((state: NebiusRootState) =>
        Boolean(selectNotebookContent(state)),
    );

    if (!isNotebookLoaded) {
        return (
            <div className={b('loader-wrapper')}>
                <Loader size="m" />
            </div>
        );
    }

    return (
        <WithStickyToolbar
            className={b()}
            toolbar={
                <div className={b('toolbar')}>
                    <JupytOperationsListContainer />
                    <CopySolutionNotebookDialog />
                    <JupytCreateDialog
                        onAfterJupytCreate={async () => {
                            await dispatch(loadNotebookJupytList());

                            dispatch(setInitialJupytAlias());
                        }}
                    />
                </div>
            }
            content={
                <div className={b('content')}>
                    <JupyterGridContainer />
                    <CommandPaletteContainer />
                </div>
            }
        />
    );
};
