import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import Icon from '@ytsaurus-ui-platform/src/ui/components/Icon/Icon';
import React, {useMemo} from 'react';
import {JupytSecretType, JupytSecretsMapType} from '../../../../../shared/types/jupyt/secrets';
import {
    mapJupytSecretsToList,
    mapListToJupytSecrets,
} from '../../../../../shared/utils/jupyt/secrets';
import block from 'bem-cn-lite';

import './SecretsEditorDialog.scss';

const b = block('secrets-editor-dialog');

type SecretsEditorDialogButtonProps = {
    onAdd: (secrets: JupytSecretsMapType) => Promise<void>;
    secrets: JupytSecretsMapType | null;
};

type FormValues = {
    secrets: JupytSecretType[];
};

export const SecretsEditorDialogButton: React.FC<SecretsEditorDialogButtonProps> = ({
    secrets,
    onAdd,
}) => {
    const [visible, setVisible] = React.useState(false);

    return (
        <React.Fragment>
            {!visible ? null : (
                <JupytSecretsEditDialog
                    secrets={secrets}
                    onAdd={onAdd}
                    onClose={() => setVisible(false)}
                />
            )}
            <Button onClick={() => setVisible(!visible)}>
                <Icon awesome={'pencil'} />
                Edit secrets
            </Button>
        </React.Fragment>
    );
};

function JupytSecretsEditDialog({
    secrets,
    onAdd,
    onClose,
}: {
    secrets: JupytSecretsMapType | null;
    onAdd: (secrets: JupytSecretsMapType) => Promise<void>;
    onClose: () => void;
}) {
    const fields = [
        {
            name: 'secrets',
            type: 'jupyter-secrets',
        } as any,
    ];

    const secretsList = useMemo(() => {
        return mapJupytSecretsToList(secrets);
    }, [secrets]);

    const initialValues = {
        secrets: secretsList,
    };

    return (
        <React.Fragment>
            <YTDFDialog<FormValues>
                className={b()}
                visible
                onClose={onClose}
                onAdd={(form) => {
                    const {values: formValues} = form.getState();

                    const secrets = mapListToJupytSecrets(formValues.secrets);

                    return onAdd(secrets);
                }}
                fields={fields}
                initialValues={initialValues}
                headerProps={{
                    title: <span>Edit kernel secrets</span>,
                }}
            />
        </React.Fragment>
    );
}
