import {combineSlices} from '@reduxjs/toolkit';
import {modalCreateNotebookSlice} from './modal-create-notebook';
import {modalCreateJupytSlice} from './modal-create-jupyt';
import {modalCopySolutionNotebookSlice} from './modal-copy-solution-notebook';

export const modals = combineSlices({
    modalCreateNotebook: modalCreateNotebookSlice.reducer,
    modalCreateJupyt: modalCreateJupytSlice.reducer,
    modalCopySolutionNotebook: modalCopySolutionNotebookSlice.reducer,
});
