import React, {useEffect} from 'react';
// @ts-ignore
import yt from '@ytsaurus/javascript-wrapper/lib/yt';
import {Loader} from '@gravity-ui/uikit';
import './ResourcesHOC.scss';
import block from 'bem-cn-lite';
import {useDispatch, useSelector} from 'react-redux';
import {
    selectIsUniversePoolExist,
    selectPoolTreeResources,
    selectPoolTreesList,
} from 'features/UniverseResources/store/selectors/resources';
import {ResourceType, resourcesSlice} from 'features/UniverseResources/store/slices/resources';
import {
    fetchPoolTrees,
    fetchResourcesUsageAndLimits,
} from 'features/UniverseResources/api/resources';
import {useUpdater} from '@ytsaurus-ui-platform/src/ui/hooks/use-updater';

const b = block('resources-hoc');

type ResourcesHOCProps = {};

export function ResourcesHOC<T extends ResourcesHOCProps>(props: React.PropsWithChildren<T>) {
    const dispatch = useDispatch();
    const isUniversePoolExist = useSelector(selectIsUniversePoolExist);
    const poolTreesList = useSelector(selectPoolTreesList);
    const poolTreesResources = useSelector(selectPoolTreeResources);

    useEffect(() => {
        yt.v3
            .exists({
                path: '//sys/pool_trees/default/universe',
            })
            .then((value: boolean) => {
                dispatch(resourcesSlice.actions.setIsUniversePoolExist({value: value}));
                dispatch(
                    resourcesSlice.actions.setVisibleResourceType({
                        value: value ? ResourceType.universe : ResourceType.global,
                    }),
                );
            });

        fetchPoolTrees().then((value) => {
            dispatch(resourcesSlice.actions.setPoolTreesList({value}));
        });
    }, []);

    const updateFn = React.useCallback(() => {
        if (!poolTreesList.length) {
            return;
        }

        fetchResourcesUsageAndLimits(poolTreesList).then((value) => {
            dispatch(resourcesSlice.actions.setPoolTreeResources({value}));
        });
    }, [poolTreesList]);

    useUpdater(updateFn);

    if (isUniversePoolExist === undefined || (isUniversePoolExist && !poolTreesResources)) {
        return (
            <div className={b('loader-wrapper')}>
                <Loader size="m" />
            </div>
        );
    }

    return props.children;
}
