import React from 'react';
import {Icon, Tooltip} from '@gravity-ui/uikit';
import CircleQuestionIcon from '@gravity-ui/icons/svgs/circle-question.svg';
import './ResourcesComponentTooltip.scss';
import block from 'bem-cn-lite';

const b = block('resoruces-component-tooltip');

type ResourcesComponentTooltipProps = {
    content?: React.ReactNode;
};

export const ResourcesComponentTooltip: React.FC<ResourcesComponentTooltipProps> = ({content}) => {
    if (!content) {
        return null;
    }

    return (
        <Tooltip content={content}>
            <Icon className={b('icon')} data={CircleQuestionIcon} size={16} />
        </Tooltip>
    );
};
