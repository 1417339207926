import {getPool, getTree} from '@ytsaurus-ui-platform/src/ui/store/selectors/scheduling/scheduling';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {ServiceDashboard} from '../../components/ServiceDashboard/ServiceDashboard';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {getServiceDashboardConfig} from '../../utils/config';
import type {Dashboard} from '@nebius-ui/spd';

type SchedulerPoolDashboardContainerProps = {};

type SchedulerPoolDashboardConfigVariables = {
    pool: [string];
    tree: [string];
    __rate_interval: [string];
    cluster: [string];
};

const dashboardConfig = (await import(
    /* webpackChunkName: "scheduler-pool" */ '../../configs/scheduler-pool.json'
)) as unknown as Dashboard;

export const SchedulerPoolDashboardContainer: React.FC<
    SchedulerPoolDashboardContainerProps
> = () => {
    const pool = useSelector(getPool);
    const tree = useSelector(getTree);
    const cluster = useSelector(getCluster);

    const config = useMemo(() => {
        const variables: SchedulerPoolDashboardConfigVariables = {
            pool: [pool],
            __rate_interval: ['30s'],
            cluster: [cluster],
            tree: [tree],
        };

        return getServiceDashboardConfig(dashboardConfig, variables);
    }, [pool, cluster, tree]);

    return (
        <ServiceDashboard
            key={config.key}
            dashboard={config.dashboard}
            variables={config.variables}
        />
    );
};
