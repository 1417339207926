import type {Dashboard} from '@nebius-ui/spd';

export const getServiceDashboardConfig = <T extends Record<string, [string]>>(
    dashboard: Dashboard,
    variables: T,
): {dashboard: Dashboard; variables: T; key: string} => {
    const key = Object.values(variables).reduce((acc, value) => {
        return `${acc}_${value}`;
    }, '');

    return {dashboard, key, variables};
};
