const PATH_HOME_PREFIX = '//';
const JUPYTER_PATH_PREFIX = '/tracto';

export const getJupyterContentManagerPath = (path: string) => {
    return `${JUPYTER_PATH_PREFIX}/${path.replace(PATH_HOME_PREFIX, '')}`;
};

export const getParentFolderPath = (path: string) => {
    return `${PATH_HOME_PREFIX}${path.replace(PATH_HOME_PREFIX, '').split('/').slice(0, -1).join('/')}`;
};

export const generateNotebookCypressId = () => crypto.randomUUID();
