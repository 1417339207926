import React from 'react';

import block from 'bem-cn-lite';
import {Label} from '@gravity-ui/uikit';
import type {JupytServerHealthType, JupytServerStateType} from 'features/Jupyter/api/jupyt';

import './JupytStateIcon.scss';

const b = block('jupyt-state-icon');

type JupytStateIconProps = {
    size?: 'm';
    className?: string;
    status: JupytServerHealthType | JupytServerStateType;
};

const THEME_MAP: Record<JupytServerHealthType | JupytServerStateType, LabelTheme> = {
    good: 'success',
    pending: 'info',
    failed: 'danger',
    active: 'success',
    inactive: 'unknown',
    untracked: 'danger',
};

type LabelTheme =
    | 'normal'
    | 'info'
    | 'danger'
    | 'warning'
    | 'success'
    | 'utility'
    | 'unknown'
    | 'clear';

export const JupytStateIcon: React.FC<JupytStateIconProps> = ({
    className,
    status,
    size,
}: JupytStateIconProps) => {
    const theme = THEME_MAP[status];
    return (
        <Label size={size} className={b(null, className)} theme={theme}>
            {status}
        </Label>
    );
};
